import { generateMapByOpts, mapHelper } from 'common/utils'

const userTypeOps = [
  {
    text: '业主',
    value: 1
  },
  {
    text: '家属',
    value: 2
  },
  {
    text: '租客',
    value: 3
  },
  {
    text: '伙伴',
    value: 4
  },
  {
    text: '嘉宾',
    value: 5
  }
]
// 套餐状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  },
  {
    text: '已删除',
    value: 2
  }
]

// 租赁方式
const rentTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '指定车位',
    value: 1
  },
  // {
  //   text: '指定区域',
  //   value: 2
  // },
  {
    text: '私人车位',
    value: 3
  }
]

// 审核状态
const stopTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '审核中',
    value: 1
  },
  {
    text: '审核通过',
    value: 2
  },
  {
    text: '审核不通过',
    value: 3
  }
]

// 套餐类型
const suitTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '包月',
    value: 1
  },
  {
    text: '包年',
    value: 2
  },
  {
    text: '免费',
    value: 3
  }
]

// 身份
const payCategoryOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '个人',
    value: 0
  },
  {
    text: '企业',
    value: 1
  }
]

// 支付状态
const payStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '用户已取消',
    value: 2
  },
  {
    text: '超时未付款',
    value: 3
  }
]

// 停车卡状态
const carCardStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '未激活',
    value: 0
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '已过期',
    value: 2
  },
  {
    text: '已关闭',
    value: 3
  }
]

// 批量审核状态
const batchCheckOps = [
  {
    text: '审核不通过',
    value: 0
  },
  {
    text: '审核通过',
    value: 1
  }
]

// 证件类型
const cardTypeOpts = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '身份证',
    value: '1'
  },
  {
    text: '军官证',
    value: '2'
  },
  {
    text: '护照',
    value: '3'
  },
  {
    text: '其他',
    value: '4'
  }
]

// 开放对象类型
const orientTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '不限',
    value: 1
  },
  {
    text: '业主',
    value: 2
  },
  {
    text: '非业主',
    value: 3
  }
]

// 来源类型
const sourceTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: 'APP',
    value: 0
  },
  {
    text: '管理后台',
    value: 1
  },
  {
    text: '园区管理App',
    value: 2
  },
  {
    text: '支付宝',
    value: 3
  }
]

// 详情页面付款状态
const applicationStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '已关闭',
    value: 2
  },
  {
    text: '已过期',
    value: 3
  },
  {
    text: '用户已取消',
    value: 4
  },
  {
    text: '超时未付款',
    value: 5
  }
]

// 详情页面付款类型
const formPayStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '未支付',
    value: 0
  },
  {
    text: '已支付',
    value: 1
  }
]

// 详情页面发票状态
const invoiceStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '开票中',
    value: 0
  },
  {
    text: '开票成功',
    value: 1
  },
  {
    text: '开票失败',
    value: 2
  },
  {
    text: '红冲（作废）',
    value: 3
  }
]

const checkInStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '未入住',
    value: 0
  }
]

// 房屋类型
const houseTypeOps = [
  {
    text: '未知',
    value: 0
  },
  {
    text: '公寓',
    value: 1
  },
  {
    text: '排屋',
    value: 2
  },
  {
    text: '别墅',
    value: 3
  },
  {
    text: '商铺',
    value: 4
  },
  {
    text: '办公用房',
    value: 5
  },
  {
    text: '经营用房',
    value: 6
  },
  {
    text: '保姆房',
    value: 21
  },
  {
    text: '储藏室',
    value: 22
  },
  {
    text: '自行车库',
    value: 23
  },
  {
    text: '车库',
    value: 24
  },
  {
    text: '车位',
    value: 25
  },
  {
    text: '其他',
    value: 26
  }
]

// 车位类型
const parkingTypeOps = [
  {
    text: '指定区域',
    value: 1
  },
  {
    text: '固定车位',
    value: 2
  }
]

const isReFoundTypeOps = [
  {
    label: '按月退',
    value: 1
  },
  {
    label: '按日退',
    value: 2
  }
]

// 支付方式
const paymentOpts = [
  {
    text: '支付宝',
    value: '支付宝'
  },
  {
    text: '微信',
    value: '微信'
  },
  {
    text: '现金',
    value: '现金'
  },
  {
    text: '银联卡',
    value: '银联卡'
  }
]

const {
  map: userTypeMap,
  setOps: setUserTypeOps
} = mapHelper.setMap(userTypeOps)

const statusMap = generateMapByOpts(statusOps)
const rentTypeMap = generateMapByOpts(rentTypeOps)
const suitTypeMap = generateMapByOpts(suitTypeOps)
const payCategoryMap = generateMapByOpts(payCategoryOps)
const payStatusMap = generateMapByOpts(payStatusOps)
const carCardStatusMap = generateMapByOpts(carCardStatusOps)
const orientTypeMap = generateMapByOpts(orientTypeOps)
const sourceTypeMap = generateMapByOpts(sourceTypeOps)
const applicationStatusMap = generateMapByOpts(applicationStatusOps)
const formPayStatusMap = generateMapByOpts(formPayStatusOps)
const invoiceStatusMap = generateMapByOpts(invoiceStatusOps)
const checkInStatusMap = generateMapByOpts(checkInStatusOps)
const houseTypeMap = generateMapByOpts(houseTypeOps)
const stopTypeMap = generateMapByOpts(stopTypeOps)
const cardTypeMap = generateMapByOpts(cardTypeOpts)

// 发票类型
const invoiceTypeMap = {
  1: '个人普票',
  2: '企业普票',
  3: '企业专票'
}

export {
  statusOps,
  statusMap,
  rentTypeOps,
  rentTypeMap,
  suitTypeOps,
  suitTypeMap,
  payCategoryOps,
  payCategoryMap,
  payStatusOps,
  payStatusMap,
  carCardStatusOps,
  carCardStatusMap,
  orientTypeOps,
  orientTypeMap,
  sourceTypeMap,
  applicationStatusMap,
  formPayStatusMap,
  invoiceStatusMap,
  checkInStatusOps,
  checkInStatusMap,
  houseTypeOps,
  houseTypeMap,
  invoiceTypeMap,
  parkingTypeOps,
  isReFoundTypeOps,
  cardTypeOpts,
  cardTypeMap,
  paymentOpts,
  setUserTypeOps,
  userTypeMap,
  stopTypeMap,
  stopTypeOps,
  batchCheckOps
}
