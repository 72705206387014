/** 停车套餐 **/
// 获取停车套餐列表
const getParkPackageListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkSuit`
// 获取停车是否有人预定
const getParkPackageOrderedURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkSuit`
// 停车套餐删除
const deleteParkPackageURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkSuit`
// 获取停车套餐详情
const getParkPackageDetailURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkSuit`
// 获取停车套餐详情页面车位信息
const getParkSuitSlotURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkSuitSlot`
// 编辑/添加停停车套餐
const saveParkPackageURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkSuit`

/** 停车订单 **/
// 获取停车订单列表
const getOrderListURL = `${API_CONFIG.butlerBaseURL}carParkApplication`
// 获取停车订单详情
const getOrderDetailURL = `${API_CONFIG.butlerBaseURL}carParkApplication`

/** 停车卡 **/
// 获取停车卡列表
const getCardListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkCard/list`
// 获取停车卡授权列表
const getCheckListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkCard/parkingCardManagerByAudit`
// 导出停车卡列表
const exportListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/export`
// 获取停车卡详情列表
const getCardDetailURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkCard`
// 编辑/添加停车卡信息
const saveCardURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkCard`
// 获取用户列表
const getOwnerListURL = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// 查询车位区域列表信息
const getCardAreaListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkApplication/carParkSuit/getCarParkZoonForRent`
// 查询车位列表信息
const getCarportListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkApplication/carParkSuit/getCarParksForRent`
// 查询备注日志接口
const getLogListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkCard/remarkList`
// 停车卡订单导出
const exportURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkOrderExport`
// 停车卡车牌号校验
const carNumValidURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carNumValid`
// 查找停车场
const getParkingByCommunityURL = `${API_CONFIG.butlerBaseURL}communityParking/community/getParkingByCommunity`
// 获取套餐
const getSuitByLocationURL = `${API_CONFIG.butlerBaseURL}carParkApplication/getSuitByLocation`
// 获取车位
const getLocationBySuitURL = `${API_CONFIG.butlerBaseURL}carParkApplication/getLocationBySuit`
// 获取住址信息列表
const getUserRoomsListURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 获取住址信息列表
const batchURL = `${API_CONFIG.butlerBaseURL}carParkApplication/addOwnerInfo`

// 获取收费科目列表
const chargeSubjectURl = `${API_CONFIG.butlerBaseURL}chargeSubjectSelect2`
// 关闭停车卡
const closeCarParkCardURL = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkCard/close`
/** 停车订单 **/
// 停车订单退款列表
const getParkRefundListURL = `${API_CONFIG.butlerBaseURL}carParkApplication/refundApplication`
// 停车订单退款列表
const getParkRefundDetail = `${API_CONFIG.butlerBaseURL}carParkApplication/refundApplication`
// 停车退款导出
const getParkRefundExport = `${API_CONFIG.butlerBaseURL}carParkApplication/carParkRefundOrderExport`

// 新增车辆
// 获取用户列表
const getOwnerList = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// 获取车辆品牌列表
// const getCarBrands = `serverCodewordAction!getCarBrands.action`
const getCarBrands = `${API_CONFIG.butlerBaseURL}sysCodeword/getSysCodeWordByCodeLevel?typeValue=CAR_BRAND_MODEL&codeLevel=1`
// 获取车辆品牌2级列表
// const getCarBrandsBySuperId = `serverOwnerAction!getCarBrandsBySuperId.action`
const getCarBrandsBySuperId = `${API_CONFIG.butlerBaseURL}sysCodeword/getSysCodeWordByCodeLevel`


// 保存车辆
// const saveOwnerCar = `serverOwnerAction!saveOwnerCar.action`
const saveOwnerCar = `${API_CONFIG.butlerBaseURL}user/register/addOwnerCarNew`
// 更新车辆
const updateOwnerCar = `${API_CONFIG.baseURL}serverOwnerAction!updateOwnerCar.action`
// 获取车辆详情
const queryOwnerCarBasic = `${API_CONFIG.baseURL}serverOwnerAction!queryOwnerCarBasic.action`
// ============

// 获取标准收费规则select2
const chargeRulePublictURl = `${API_CONFIG.butlerBaseURL}chargeRulePublicSelect2`
// 车辆行驶证上传接口
const licensePicUploadUrl = `${API_CONFIG.uploadURL}?module=shopgoods`
// const licensePicUploadUrl = `https://yshyanfa.lcfw.co/upload?module=shopgoods`

// 获取用户名下车辆
// const getCarListByUser = `${API_CONFIG.butlerBaseURL}user/register/getCarsByUserId`
const getCarListByUser = `${API_CONFIG.butlerBaseURL}carParkApplication/queryOwnerCar`
// 流程审核
const processCheck = `${API_CONFIG.butlerBaseURL}carParkApplication/completeTask`


//   // 新增车辆
// // 获取用户列表
// const getOwnerList = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// // 获取车辆品牌列表
// const getCarBrands = `${API_CONFIG.baseURL}serverCodewordAction!getCarBrands.action`
// // 获取车辆品牌2级列表
// const getCarBrandsBySuperId = `${API_CONFIG.baseURL}serverOwnerAction!getCarBrandsBySuperId.action`
// // 保存车辆
// const saveOwnerCar = `${API_CONFIG.baseURL}serverOwnerAction!saveOwnerCar.action`
// // 更新车辆
// const updateOwnerCar = `${API_CONFIG.baseURL}serverOwnerAction!updateOwnerCar.action`
// // 获取车辆详情
// const queryOwnerCarBasic = `${API_CONFIG.baseURL}serverOwnerAction!queryOwnerCarBasic.action`
// // ============

// // 获取标准收费规则select2
// const chargeRulePublictURl = `${API_CONFIG.butlerBaseURL}chargeRulePublicSelect2`

/**
 * 获取停车授权审核流程
 */
const getParkingCheckListURl = `${API_CONFIG.butlerBaseURL}carParkApplication/getParkingCheckList`
export {
  /** 停车套餐 **/
  getParkPackageListURL,
  getParkPackageOrderedURL,
  deleteParkPackageURL,
  getParkPackageDetailURL,
  getParkSuitSlotURL,
  saveParkPackageURL,
  /** 停车订单 **/
  getOrderListURL,
  getOrderDetailURL,
  /** 停车卡 **/
  getCardListURL,
  exportListURL,
  getCardDetailURL,
  saveCardURL,
  getOwnerListURL,
  getCardAreaListURL,
  getCarportListURL,
  getLogListURL,
  exportURL,
  carNumValidURL,
  closeCarParkCardURL,
  /** 停车订单退款 **/
  getParkRefundListURL,
  getParkRefundDetail,
  getParkRefundExport,
  getParkingByCommunityURL,
  getSuitByLocationURL,
  getLocationBySuitURL,
  getUserRoomsListURL,
  batchURL,
  chargeSubjectURl,
  // 新增车辆
  getOwnerList,
  getCarBrands,
  getCarBrandsBySuperId,
  saveOwnerCar,
  updateOwnerCar,
  queryOwnerCarBasic,
  chargeRulePublictURl,
  licensePicUploadUrl,
  getCarListByUser,
  processCheck,
  getCheckListURL,
  // 获取(停车授权审核流程)流程记录
  getParkingCheckListURl
}
